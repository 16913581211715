/* global _api */
import React, { Component } from "react";
import Card from "../Card/Card.jsx";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PropagateLoader from 'react-spinners/PropagateLoader';
import TrackerMap from '../Map/TrackerMap.jsx';
import 'react-vertical-timeline-component/style.min.css';
import image from "../../assets/img/expert/logo-expert-white-background.png";
import moment from "moment";
import "moment/min/locales";
import {
    Badge,
    Row,
    Col,
    Button,
    FormControl,
    FormGroup,
    ControlLabel
} from "react-bootstrap";

moment.locale('pl');


class ExpertTrackerTransferComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            steps: [],
            orderStatus: "loading",
            orderTrash: false,
            orderTracker: "",
            orderIdent: "",
            companyName: "",
        };
    }

    componentDidMount() {
        let self = this;
        let orderTracker = self.props.orderTracker || null;
        if (!orderTracker) {
            self.setState({ orderStatus: "notfound" });
            return;
        }
        self.setState({ orderStatus: "loading", isLoading: true, orderTracker: orderTracker },
            () => {
                self.getCurrentData();
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
        if (this.tOutData) {
            clearTimeout(this.tOutData);
        }
    }

    getCurrentData() {
        let self = this;
        _api.request(`/tracking/status/${self.state.orderTracker}`,
            {
                withDetails: true,
                withTransfer: true
            },
            (data) => {
                self.setState({
                    steps: data.order.transfer ? data.order.transfer.reverse() : [],
                    orderStatus: "found",
                    orderTrash: data.order.trash || false,
                    orderTracker: data.order.tracker,
                    orderIdent: data.order.ident,
                    companyName: data.company.name,
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                self.setState({ orderStatus: "notfound" });
            }
        );
    }

    render() {

        const orderLoading = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        let orderDetails = (
            <div>
                <p>Śledzenie transferu numer <strong>{this.state.orderIdent} {this.state.orderTrash ? <Badge bsStyle="danger">anulowane</Badge> : <></>}</strong> - {this.state.companyName}.</p>
                {
                    this.state.steps.length && (
                        <VerticalTimeline layout="1-column">
                            {this.state.steps.map(step => (
                                <VerticalTimelineElement
                                    key={step.uuid}
                                    contentStyle={{ border: '1px solid #007bff', boxShadow: 'none', color: "#007bff" }}
                                    contentArrowStyle={{ borderRight: '7px solid #007bff' }}
                                    iconStyle={{ background: "#007bff", color: '#fff', boxShadow: 'none' }}
                                    icon={<div style={{ textAlign: "center" }}><i style={{ fontSize: "20px", lineHeight: "40px", width: "40px" }} className="fa fa-truck"></i></div>}
                                >
                                    <h4 className="vertical-timeline-element-title">{step.name}</h4>
                                    <h5 className="vertical-timeline-element-subtitle">{step.updated || step.created}</h5>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col sm={6}>
                                            {step.details.currentWarehouse && (
                                                <div>Z: {step.details.currentWarehouse.name}</div>
                                            )}
                                            {step.details.nextWarehouse && (
                                                <div>DO: {step.details.nextWarehouse.name}</div>
                                            )}
                                        </Col>
                                        <Col sm={6}>
                                            {step.details.driver.lastname && (
                                                <>
                                                    <div>KIEROWCA: {step.details.driver.firstname} {step.details.driver.lastname}</div>
                                                    <div>{step.details.driver.vehicleNumber}</div>
                                                    <div>{step.details.driver.comment}</div>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </VerticalTimelineElement>
                            ))}
                        </VerticalTimeline>
                    )
                }
            </div>
        );

        let orderNotFound = (
            <span>Zamównienie o podanym numerze nie zostało znalezione.</span>
        );

        let orderResponse = (<></>);

        switch (this.state.orderStatus) {
            case 'loading':
                orderResponse = (<>{orderLoading}</>);
                break;

            case 'notfound':
                orderResponse = (<>{orderNotFound}</>);
                break;

            case 'found':
                orderResponse = (<>{orderDetails}</>);
                break;

            default:
                orderResponse = (<></>);
                break;
        }

        let orderInContainer = (
            <>
                <Card
                    title={this.state.withDetails ? "Status transferu" : "Szczegóły transferu"}
                    content={
                        <div className="main-content">
                            {orderResponse}
                            <a href="https://expert.suus.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', width: 150, margin: '20px auto' }}>
                                <img src={image} style={{ width: 150 }} />
                            </a>
                        </div>
                    }
                />
            </>
        )

        return orderInContainer;

    }

}

export default ExpertTrackerTransferComponent;
